import axios from "axios";
import { authHeader } from "@/helpers/authentication-header";

const API_ENDPOINT = "https://gmbsms.org.uk/api/";
// const API_ENDPOINT = "https://netrixapi.co.uk/api/";

//getProfile
export function APIdoGetUser() {
  const url = API_ENDPOINT + "profile";
  return axios.get(url, {
    // withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...authHeader(),
    },
  });
}

export function APIdoUpdateProfile(data) {
  const url = API_ENDPOINT + "profile";
  return axios.post(url, data, {
    // withCredentials: true,
    params: {},
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...authHeader(),
    },
  });
}

//member
export function APIdoGetMembers(page, limit) {
  const url = API_ENDPOINT + "members";
  return axios.get(url, {
    // withCredentials: true,
    params: {
      page: page,
      limit: limit,
    },
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...authHeader(),
    },
  });
}

export function APIdoUploadMembers(file) {
  const url = API_ENDPOINT + "members";
  return axios.post(url, file, {
    // withCredentials: true,
    params: {},
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...authHeader(),
    },
  });
}

export function APIdoRemoveMember(id) {
  const url = API_ENDPOINT + "members/" + id;
  return axios.delete(url, {
    // withCredentials: true,
    params: {},
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...authHeader(),
    },
  });
}

export function APIdoMultiRemoveMember(data) {
  const url = API_ENDPOINT + "members/multi/remove";
  return axios.post(
    url,
    { data: JSON.stringify(data) },
    {
      // withCredentials: true,
      params: {},
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        ...authHeader(),
      },
    }
  );
}

export function APIdoMultiArchiveMember(data) {
  const url = API_ENDPOINT + "members/multi/archive";
  return axios.post(
    url,
    { data: JSON.stringify(data) },
    {
      // withCredentials: true,
      params: {},
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        ...authHeader(),
      },
    }
  );
}

export function APIdoGenerateReport(data) {
  const url = API_ENDPOINT + "report";
  return axios.post(url, data, {
    withCredentials: true,
    params: {},
    responseType: "blob",
    headers: {
      "Content-Type": "multipart/form-data",
      "Content-Disposition": "attachment",
      "Access-Control-Expose-Headers": "Access-Token, Uid",

      // "Access-Control-Request-Headers": "Authorization",
      // Accept: "application/json",
      ...authHeader(),
    },
  });
}

//user

export function APIdoGetUsers(page, limit) {
  const url = API_ENDPOINT + "users";
  return axios.get(url, {
    // withCredentials: true,
    params: {
      page: page,
      limit: limit,
    },
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...authHeader(),
    },
  });
}

export function APIdoAddUser(data) {
  const url = API_ENDPOINT + "users";
  return axios.post(url, data, {
    // withCredentials: true,
    params: {},
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...authHeader(),
    },
  });
}

export function APIdoUpdateUser(data) {
  const url = API_ENDPOINT + "users/" + data.id;
  return axios.post(url, data, {
    // withCredentials: true,
    params: {},
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...authHeader(),
    },
  });
}

export function APIdoRemoveUser(id) {
  const url = API_ENDPOINT + "users/" + id;
  return axios.delete(url, {
    // withCredentials: true,
    params: {},
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...authHeader(),
    },
  });
}

//logs
export function APIdoGetLogs(page, limit, level, term = "") {
  const url = API_ENDPOINT + "log";
  return axios.get(url, {
    // withCredentials: true,
    params: {
      level: level,
      page: page,
      limit: limit,
      term: term,
    },
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...authHeader(),
    },
  });
}

export function APIdoRemoveLogs() {
  const url = API_ENDPOINT + "log";
  return axios.delete(url, {
    // withCredentials: true,
    params: {},
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...authHeader(),
    },
  });
}

export function APIdoDownloadLogs() {
  const url = API_ENDPOINT + "log";
  return axios.post(
    url,
    {},
    {
      // withCredentials: true,
      params: {},
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        ...authHeader(),
      },
    }
  );
}

//settings

export function APIdoGetSettings() {
  const url = API_ENDPOINT + "settings";
  return axios.get(url, {
    // withCredentials: true,
    params: {},
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...authHeader(),
    },
  });
}

export function APIdoGetMessages() {
  const url = API_ENDPOINT + "messages";
  return axios.get(url, {
    // withCredentials: true,
    params: {},
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...authHeader(),
    },
  });
}

export function APIdoGetSchedules() {
  const url = API_ENDPOINT + "schedules";
  return axios.get(url, {
    // withCredentials: true,
    params: {},
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...authHeader(),
    },
  });
}

export function APIdoUpdateSmsSetting(data) {
  const url = API_ENDPOINT + "settings/sms";
  return axios.post(url, data, {
    // withCredentials: true,
    params: {},

    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...authHeader(),
    },
  });
}

export function APIdoUpdateSmtpSetting(data) {
  const url = API_ENDPOINT + "settings/smtp";
  return axios.post(url, data, {
    // withCredentials: true,
    params: {},

    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...authHeader(),
    },
  });
}

export function APIdoUpdateEmailSetting(data) {
  const url = API_ENDPOINT + "settings/emails";
  return axios.post(url, data, {
    // withCredentials: true,
    params: {},

    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...authHeader(),
    },
  });
}

export function APIdoUpdateSMSMessagesSetting(data, region) {
  const url = API_ENDPOINT + "messages?region=" + region;
  return axios.post(url, data, {
    // withCredentials: true,
    params: {},

    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...authHeader(),
    },
  });
}

export function APIdoUpdateSMSSchedulesSetting(data, region) {
  const url = API_ENDPOINT + "schedules?region=" + region;
  return axios.post(url, data, {
    // withCredentials: true,
    params: {},

    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...authHeader(),
    },
  });
}

export function APIdoSmsGenerate() {
  const url = API_ENDPOINT + "settings/token";
  return axios.post(
    url,
    {},
    {
      // withCredentials: true,
      params: {},
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        ...authHeader(),
      },
    }
  );
}
